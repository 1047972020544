import React, { useEffect } from 'react';
import { useSelector } from 'react-redux';
import './Home.scss';
import MainButton from '../MainButton/MainButton';
import CompareFloorplansIcon from '../CompareFloorplansIcon/CompareFloorplansIcon';
import DragInfo from '../DragInfo/DragInfo';
import { useScrollBoost } from 'react-scrollbooster';
import Cover from '../Cover/Cover';
import MobileFooter from '../MobileFooter/MobileFooter';

const homeHeroTexture = {
    backgroundImage: "url('/img-temp/home-hero-bg.jpg')"
};

function hideDragInfo() {
    if (document.querySelector('.home-viewport').scrollLeft > 40) {
        document.querySelector('.drag-info').style.opacity = '0';
    } else {
        document.querySelector('.drag-info').style.opacity = '1';
    }
}

const Home = () => {
    const content = useSelector((state) => state.content.pages.home);
    const isTouchScreen = useSelector((state) => state.utilities.isTouchScreen);

    const [viewport] = useScrollBoost({
        direction: 'all',
        friction: 0.05,
        scrollMode: 'native'
    });

    useEffect(() => {
        let item = document.querySelector('.home-viewport');

        window.addEventListener('wheel', function (e) {
            if (e.deltaY > 0) {
                if (item) item.scrollLeft += 100;
            } else if (e.deltaY < 0) {
                if (item) item.scrollLeft -= 100;
            }
        });
    });

    if (typeof content !== 'undefined') {
        return (
            <div
                ref={viewport}
                className="home-viewport"
                onScroll={hideDragInfo}
            >
                <div className="home">
                    <Cover />
                    <DragInfo />
                    <div className="home-fullscreen" style={homeHeroTexture}>
                        <div className="home-hero">
                            <div className="home-hero-flex">
                                <h1 className="home-hero-title">
                                    <div
                                        dangerouslySetInnerHTML={{
                                            __html: content.intro.heading
                                        }}
                                    ></div>
                                </h1>
                                <div className="home-hero-tagline">
                                    {content.intro.copy}
                                </div>
                                <div
                                    className="home-hero-price"
                                    dangerouslySetInnerHTML={{
                                        __html: content.intro.starting_from
                                    }}
                                />
                            </div>
                            <div className="home-hero-flex-image">
                                <img
                                    src={content.intro.image.url}
                                    alt={content.intro.image.alt}
                                />
                            </div>
                        </div>
                        <div className="home-fullscreen-image-container">
                            {isTouchScreen && (
                                <div className="disclaimer">
                                    The developer reserves the right to make
                                    changes and modifications to the information
                                    contained herein without prior notice.
                                    Renderings, maps and photographs are
                                    representational only and may not be
                                    accurate. Price and promotions are subject
                                    to change without notice. E.&amp;O.E.
                                </div>
                            )}
                            <img
                                src={content.intro.large_image}
                                alt=""
                                className="image-1"
                            />
                        </div>
                    </div>
                    <div className="home-content-1">
                        <div className="container">
                            <div className="top">
                                {content.collection.heading.top_heading}
                            </div>
                            <div className="number">
                                {content.collection.heading.large_heading}
                            </div>
                            <div className="bottom">
                                <div
                                    dangerouslySetInnerHTML={{
                                        __html:
                                            content.collection.heading
                                                .bottom_heading
                                    }}
                                />
                            </div>
                            <div className="copy">
                                <div
                                    dangerouslySetInnerHTML={{
                                        __html: content.collection.copy
                                    }}
                                />
                            </div>
                            {!isTouchScreen && (
                                <MainButton
                                    text={content.collection.link_button.text}
                                    svg={<CompareFloorplansIcon />}
                                    link={content.collection.link_button.url}
                                    external
                                    blank
                                    style={{
                                        padding: '24px 40px',
                                        width: '368px',
                                        margin: '0 auto'
                                    }}
                                />
                            )}
                        </div>
                    </div>
                    <img
                        src={content.collection.large_image.url}
                        alt={content.collection.large_image.alt}
                        className="full-height-image"
                    />
                    <div className="home-fullscreen home-fullscreen-green">
                        <div className="home-content-2">
                            <div className="container">
                                <h2 className="title">
                                    {content.floorplans.heading}
                                </h2>
                                <div className="copy">
                                    {content.floorplans.copy}
                                </div>
                                <MainButton
                                    link={content.floorplans.link_button.url}
                                    text={content.floorplans.link_button.text}
                                    svg={<CompareFloorplansIcon />}
                                    svgAbsolute={true}
                                    style={{
                                        padding: '24px 40px',
                                        width: '368px',
                                        margin: '0 auto'
                                    }}
                                />
                            </div>
                        </div>
                        <div className="home-fullscreen-image-container">
                            <img
                                src={content.floorplans.large_image.url}
                                alt={content.floorplans.large_image.alt}
                                // className="full-height-image"
                            />
                        </div>
                    </div>
                    <div className="home-content-3">
                        <div className="container">
                            <h2 className="title">{content.area.heading}</h2>
                            <div className="copy">{content.area.copy}</div>
                            <MainButton
                                link={content.area.link_button.url}
                                text={content.area.link_button.text}
                                style={{
                                    padding: '24px 40px'
                                }}
                            />
                        </div>
                    </div>
                    <img
                        src={content.area.large_image.url}
                        alt={content.area.large_image.alt}
                        className="full-height-image"
                    />
                    <MobileFooter showBelow={1025} />
                </div>
            </div>
        );
    } else {
        return <div></div>;
    }
};

export default Home;
