import {
    CONTENT_QUERY,
    CONTENT_UPDATE_FORM,
    CONTENT_UPDATE,
    CONTENT_REMOVE_FORM_FIELD_ERROR,
    CONTENT_FORM_SUBMIT_QUERY,
    CONTENT_REMOVE_CONSENT_ERROR
} from '../actions/actionTypes';

function findFormFieldIdByName(name, fields) {
    for(var i = 0; i < fields.length; i++) {
        if(fields[i].name === name) {
            return i;
        }
    }
    
    return -1;
}

const initalState = {
    pages: [],
    menu: [],
    options: {}
};

function content(state = initalState, action) {
    switch(action.type) {
        case CONTENT_QUERY:
            return state;
        
        case CONTENT_UPDATE:
            action.content.options.form_submitted = false;
            action.content.options.form_submitting = false;

            return Object.assign({}, state, {
                pages: action.content.pages,
                menu: action.content.menu,
                options: action.content.options
            });
        case CONTENT_FORM_SUBMIT_QUERY:
            var options = Object.assign({}, state.options);
            options.form_submitting = true;

            return Object.assign({}, state, {
                options: options
            });
        case CONTENT_UPDATE_FORM:
            //i need to update the error state of each form field. 
            //if the error state is true, then that field should display the form field error
            //for a while Im going to do that as part of the frontend though
            var form = [].concat(state.options.form);
            var options = Object.assign({}, state.options);

            var keys = Object.keys(action.results.errors);

            if(keys.length > 0) {
                for(var i = 0; i < keys.length; i++) {
                    var key = keys[i];
                    
                    var fieldID = findFormFieldIdByName(key, form);
                    if(fieldID !== -1) {
                        //mark this field as having an error.
                        form[fieldID].error = true; 
                    }

                    if(key === 'consent') {
                        options.consent_error = true;
                    }
                }
            }

            
            options.form = form;
            options.form_submitting = false;
            
            if(action.results.success) {
                options.form_submitted = true;
            }

            return Object.assign({}, state, {
                options: options
            });
        case CONTENT_REMOVE_FORM_FIELD_ERROR:
            var form = [].concat(state.options.form);
            form[action.index].error = false;

            var options = Object.assign({}, state.options);
            options.form = form;

            return Object.assign({}, state, {
                options: options
            });
        case CONTENT_REMOVE_CONSENT_ERROR:
            var options = Object.assign({}, state.options);
            options.consent_error = false;

            return Object.assign({}, state, {
                options: options
            });
        default:
            return state;
    }
}

export default content;