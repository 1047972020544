import React, { useEffect } from "react";
import "./Area.scss";
import { useScrollBoost } from "react-scrollbooster";
import Cover from "../Cover/Cover";
import DragInfo from "../DragInfo/DragInfo";
import Amenities from "../Amenities/Amenities";
import { useSelector } from "react-redux";
import MobileFooter from "../MobileFooter/MobileFooter";

const Area = (props) => {
  const content = useSelector((state) => state.content.pages.area);

  const [viewport] = useScrollBoost({
    direction: "all",
    friction: 0.05,
    scrollMode: "native",
    onClick: (state, event) => {
      if (state.isDragging) {
        event.preventDefault();
      }
    },
  });

  function hideDragInfo() {
    if (document.querySelector(".area-viewport").scrollLeft > 40) {
      document.querySelector(".drag-info").style.opacity = "0";
    } else {
      document.querySelector(".drag-info").style.opacity = "1";
    }
  }

  useEffect(() => {
    let item = document.querySelector(".area-viewport");

    window.addEventListener("wheel", function (e) {
      if (e.deltaY > 0) {
        if (item) item.scrollLeft += 100;
      } else if (e.deltaY < 0) {
        if (item) item.scrollLeft -= 100;
      }
    });
  });

  //   const mergeRefs = (...refs) => {
  //     const filteredRefs = refs.filter(Boolean);
  //     if (!filteredRefs.length) return null;
  //     if (filteredRefs.length === 0) return filteredRefs[0];
  //     return (inst) => {
  //       for (const ref of filteredRefs) {
  //         if (typeof ref === "function") {
  //           ref(inst);
  //         } else if (ref) {
  //           ref.current = inst;
  //         }
  //       }
  //     };
  //   };

  //   return <div></div>;

  if (typeof content !== "undefined") {
    return (
      <div
        // ref={mergeRefs(viewport, props.areaViewport)}
        ref={viewport}
        className="area-viewport"
        onScroll={hideDragInfo}
      >
        <div className="area">
          <Cover />
          <DragInfo />
          <div id="image-load-target">
            <div className="area-section-1" id="overview">
              <div className="flex-container">
                <img
                  src={content.overview.heading_small_image.url}
                  className="area-image-1"
                  alt={content.overview.heading_small_image.alt}
                />
                <h1
                  dangerouslySetInnerHTML={{
                    __html: content.overview.heading,
                  }}
                />
              </div>
              <img
                src={content.overview.heading_large_image.url}
                className="area-image-2"
                alt={content.overview.heading_large_image.alt}
              />
              <img
                src={content.overview.flowers.url}
                className="area-flowers"
                alt={content.overview.flowers.alt}
              />
            </div>
            <div className="area-section-2">
              <img
                src={content.overview.copy_top_image.url}
                className="area-image-3"
                alt={content.overview.copy_top_image.alt}
              />
              <p>{content.overview.copy}</p>
              <img
                src={content.overview.copy_bottom_image.url}
                className="area-image-4"
                alt={content.overview.copy_bottom_image.alt}
              />
            </div>
            <div className="area-section-3">
              <img
                src={content.overview.display_image.url}
                className="area-image-5"
                alt={content.overview.display_image.alt}
              />
            </div>
          </div>
          <div className="area-section-4" id="trails">
            <img
              src="/img-temp/area-4-flowers.jpg"
              className="area-section-4-flowers"
              alt="Flowers"
            />
            <div className="compass-section">
              <h2>{content.trails.heading}</h2>
              <div className="compass-container">
                <img src="/img-temp/directions.png" alt="Compass directions" />
                <img
                  src="/img-temp/big-compass.png"
                  alt="Compass"
                  className="big-compass"
                />
              </div>
              <p
                dangerouslySetInnerHTML={{
                  __html: content.trails.copy,
                }}
              />
            </div>
            <div className="map-illustration-container">
              <img src={content.trails.map.url} alt={content.trails.map.alt} />
            </div>
            <div className="area-image-6-container">
              <img
                src={content.trails.glasco.large_image.url}
                className="area-image-6"
                alt={content.trails.glasco.large_image.alt}
              />
            </div>
          </div>
          <div className="area-section-5">
            <div className="top">
              <img
                src={content.trails.glasco.right_image.url}
                className="area-image-7"
                alt={content.trails.glasco.right_image.alt}
              />
            </div>
            <div className="bottom">
              <img
                src={content.trails.glasco.left_image.url}
                className="area-image-8"
                alt={content.trails.glasco.left_image.alt}
              />
              <div className="copy-container">
                <h3
                  dangerouslySetInnerHTML={{
                    __html: content.trails.glasco.heading,
                  }}
                />
                <p>{content.trails.glasco.copy}</p>
              </div>
            </div>
            <img
              src="/img-temp/flowers.jpg"
              className="area-section-5-flowers"
              alt="Flowers"
            />
          </div>
          <div className="keyline"></div>
          <div className="area-section-6">
            <div className="area-image-9-container">
              <img
                src={content.trails.boyd_nature.large_image.url}
                className="area-image-9"
                alt={content.trails.boyd_nature.large_image.alt}
              />
            </div>
          </div>
          <div className="area-section-7">
            <div className="top">
              <div className="image-container">
                <img
                  src={content.trails.boyd_nature.bird_image.url}
                  className="bird"
                  alt={content.trails.boyd_nature.bird_image.alt}
                />
                <img
                  src={content.trails.boyd_nature.copy_image.url}
                  className="area-image-10"
                  alt={content.trails.boyd_nature.copy_image.alt}
                />
              </div>
              <p>{content.trails.boyd_nature.copy}</p>
            </div>
            <div className="bottom">
              <h3>
                <span className="first">
                  {content.trails.boyd_nature.heading.first}
                </span>
                <span className="second">
                  {content.trails.boyd_nature.heading.second}
                </span>
              </h3>
            </div>
          </div>
          <div className="area-section-8">
            <div className="area-image-11-container">
              <img
                src={content.trails.boyd_conservation.large_image.url}
                className="area-image-11"
                alt={content.trails.boyd_conservation.large_image.alt}
              />
            </div>
          </div>
          <div className="keyline"></div>
          <div className="area-section-9">
            <div className="top">
              <img
                src={content.trails.boyd_conservation.image.url}
                className="area-image-12"
                alt={content.trails.boyd_conservation.image.alt}
              />
              <p>{content.trails.boyd_conservation.copy}</p>
            </div>
            <div className="bottom">
              <h3>
                <span className="first">
                  {content.trails.boyd_conservation.heading.first}
                </span>
                <span className="second">
                  {content.trails.boyd_conservation.heading.second}
                </span>
                <span className="third">
                  {content.trails.boyd_conservation.heading.third}
                </span>
              </h3>
            </div>
            <img
              src="/img-temp/area-section-9-flowers.jpg"
              className="flowers"
              alt="Flowers"
            />
          </div>
          <div className="area-section-10" id="area">
            <div className="copy">
              <h4>{content.area.heading}</h4>
              <p>{content.area.copy}</p>
              <div className="image-container">
                <img
                  src="/img-temp/area-10-flower.png"
                  className="area-section-10-flower"
                  alt="Flower"
                />
              </div>
            </div>
            <div className="area-gallery">
              {content.area.gallery.map((img, key) => (
                <div className="container" key={key}>
                  <img src={img.image.url} alt={img.image.alt} />
                  {img.heading !== "" && (
                    <div className="caption">{img.heading}</div>
                  )}
                  {/*img.copy !== '' && (
                                        <div className="caption-copy">
                                            {img.copy}
                                        </div>
                                    )*/}
                  <a className="caption-address" href={img.link} target="blank">
                    {img.copy}
                  </a>
                </div>
              ))}
            </div>
          </div>
          <Amenities map={content.map} blank={content.blank_map_image} />
          <MobileFooter showBelow={1300} />
        </div>
      </div>
    );
  } else {
    return <div></div>;
  }
};

export default Area;
