import React, { useEffect } from "react";
import {
  BrowserRouter,
  Switch,
  Route,
  Redirect,
  useHistory,
} from "react-router-dom";
import MainHeader from "../MainHeader/MainHeader";
import Home from "../Home/Home";
import SelectProducts from "../SelectProducts/SelectProducts";
import FloorplansGrid from "../FloorplansGrid/FloorplansGrid";
import FloorplanListContainer from "../FloorplanListContainer/FloorplanListContainer";
import SearchHeader from "../SearchHeader/SearchHeader";
import ListGridHeader from "../ListGridHeader/ListGridHeader";
import FloorplanDetails from "../FloorplanDetails/FloorplanDetails";
import CompareFloorplans from "../CompareFloorplans/CompareFloorplans";
import About from "../About/About";
import Subheader from "../Subheader/Subheader";
import GalleryVideos from "../GalleryVideos/GalleryVideos";
import GalleryRenderings from "../GalleryRenderings/GalleryRenderings";
import GalleryArea from "../GalleryArea/GalleryArea";
import FeaturesAndFinishes from "../FeaturesAndFinishes/FeaturesAndFinishes";
import SitePlan from "../SitePlan/SitePlan";
import Contact from "../Contact/Contact";
import AreaPage from "../AreaPage/AreaPage";
import PrivacyPolicy from "../PrivacyPolicy/PrivacyPolicy";
import Tracker from "../Tracker/Tracker";
import SurveyPopup from "../SurveyPopup/SurveyPopup";

function Router() {
  return (
    <BrowserRouter>
      <Tracker />
      <MainHeader></MainHeader>
      <Switch>
        <Route path="/site-plan">
          <SitePlan />
        </Route>
        <Route path="/floorplans/select-products">
          <SelectProducts />
        </Route>
        <Route path="/floorplans/list">
          <ListGridHeader isListPage={true}></ListGridHeader>
          <SearchHeader></SearchHeader>
          <FloorplanListContainer />
        </Route>
        <Route path="/floorplans/grid">
          <ListGridHeader
            listGridHeaderBorder={true}
            isGridPage={true}
          ></ListGridHeader>
          <SearchHeader></SearchHeader>
          <FloorplansGrid></FloorplansGrid>
        </Route>
        <Route path="/floorplans/details">
          <FloorplanDetails />
        </Route>
        <Route path="/floorplans/compare">
          <CompareFloorplans />
        </Route>
        <Route path="/about">
          <About></About>
        </Route>
        <Route exact path="/gallery">
          <Redirect to="/gallery/videos" />
        </Route>
        <Route path="/gallery/videos">
          <Subheader></Subheader>
          <GalleryVideos></GalleryVideos>
        </Route>
        <Route path="/gallery/renderings">
          <Subheader></Subheader>
          <GalleryRenderings></GalleryRenderings>
        </Route>
        <Route path="/gallery/area">
          <Subheader></Subheader>
          <GalleryArea></GalleryArea>
        </Route>
        <Route path="/area">
          <AreaPage></AreaPage>
        </Route>
        <Route path="/features-and-finishes">
          <FeaturesAndFinishes></FeaturesAndFinishes>
        </Route>
        <Route path="/contact-us">
          <Contact></Contact>
        </Route>
        <Route path="/privacy-policy">
          <PrivacyPolicy></PrivacyPolicy>
        </Route>
        <Route path="/floorplans" exact>
          <Redirect to="/floorplans/select-products" />
        </Route>
        <Route path="/" exact>
          <Home></Home>
        </Route>
      </Switch>
    </BrowserRouter>
  );
}

export default Router;
